import React, {useEffect, useRef} from "react";
import {TimelineLite} from "gsap";
import NotificationSound from "../../sound/notification_sound.mp3";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import "../AudioPlayer/AudioPlayer.css";


import "./about.css";
import '../../layouts/extra.css';
import DownloadIcon from "./../../images/icons/download.svg";
import ModalExp from "../modal-exp/modal-exp";
import MyPhoto from "../my-photo/my-photo";

const About = () => {
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const btnDownloadRef = useRef(null);
  const myPhotoRef = useRef(null);
  const tl = new TimelineLite({paused: true});
  useEffect(() => {
    tl.to(titleRef.current, 0.45, {opacity: 1, y: 0})
      .to(subtitleRef.current, 0.25, {opacity: 1, y: 0}, "-=0.1")
      .to(btnDownloadRef.current, 0.25, {opacity: 1, y: 0}, "-=0.1")
      .to(myPhotoRef.current, 0.35, {opacity: 1}, "-=0.1")
      .play();
  }, []);

  const playNotificationSound = () => {
    const audio = new Audio(NotificationSound);
    audio.play();
  };

  return (
    <section className="about">
      <div className="about__body">
        <h2 className="h1 slide-down mb-0" ref={titleRef}>
          Hi, I&rsquo;m <p className="z-40 mb-0 text-5xl h-28 argb bg-clip-text text-transparent font-bold">Pratyay Banerjee</p>
        </h2>
        <p className="about__subtitle slide-down" ref={subtitleRef}>
        I am a passionate <em><s>Software Developer</s></em> <b>Innovator</b> striving to develop solutions for challenging problems by intersecting <em><b>Web</b></em> and <em><b>Machine Learning</b></em> to create amazing stuff that brings positive change in human lives. I have more than <b>2.5</b>&nbsp;years of<ModalExp /> surrounding&nbsp; Web, OSS and tech in general. Besides, I also love tinkering with electronics, participate in <em>Hacakthons</em> or listen to EDM when I&rsquo;m usually free. 
        </p>
        <div className="about__footer">
          <div className="about__listen-section">
            <span className="about__listen-text">CV Podcast (9m) </span>
            <span className="uparKaro">
            <span className="arrow primera next"></span>
            <span className="arrow segunda next"></span>
            </span>
            <div className="about__audio-player">
              <AudioPlayer />
            </div>
          </div>
          {/* <span className="text-gray-500">/</span> */}
          <div className={`slide-down about__download-btn ml-10`} ref={btnDownloadRef}>
            <a 
              href="https://drive.google.com/uc?export=download&id=1vbK6INQ7I9f3DB9MA_IM_AtVYVwSXNcl" 
              download 
              className="button-download" 
              onClick={playNotificationSound}
            >
              <DownloadIcon aria-hidden={true} />
              <span className="button-download__text">Download CV</span>
              <span className="button-download__info">(pdf 198kb)</span>
            </a>
          </div>
        </div>
      </div>
      <div className="about__col colx">
        <div className={`fade`} ref={myPhotoRef}>
          {<MyPhoto />}
        </div>
        <a 
          href="https://drive.google.com/uc?export=download&id=1vbK6INQ7I9f3DB9MA_IM_AtVYVwSXNcl" 
          className="button-download" 
          onClick={playNotificationSound}
        >
          <DownloadIcon aria-hidden={true} />
          <span className="button-download__text">Download CV</span>
          <span className="button-download__info">(pdf 198kb)</span>
        </a>
      </div>
    </section>
  );
};

export default About;
