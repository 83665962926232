import React, { useRef, useEffect, useState, useCallback } from "react";

const AudioPlayer = () => {
  const audioRef = useRef(null);
  const circleRef = useRef(null);
  const dotRef = useRef(null);
  const pathRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [totalLength, setTotalLength] = useState(0);

  const handleDrag = useCallback(
    (e) => {
      const bounds = circleRef.current.getBoundingClientRect();
      const radius = bounds.width / 2;
      const dx = e.clientX - (bounds.left + radius);
      const dy = e.clientY - (bounds.top + radius);
      let angle = Math.atan2(dy, dx);
      if (angle < 0) {
        angle += 2 * Math.PI; // Add 2π if angle is less than 0
      }
      // Adjust for the SVG circle's 0 degrees being at the 3 o'clock position
      angle = (angle + Math.PI / 2) % (2 * Math.PI);
      const percentage = (angle / (2 * Math.PI)) * 100;
      updateAudio(percentage);

      const point = pathRef.current.getPointAtLength(
        (percentage / 100) * totalLength
      );
      dotRef.current.setAttribute("cx", point.x);
      dotRef.current.setAttribute("cy", point.y);
    },
    [totalLength]
  );

  useEffect(() => {
    const path = pathRef.current;
    const length = path.getTotalLength();
    setTotalLength(length);
    path.style.strokeDasharray = length;
    path.style.strokeDashoffset = length;
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    handleDrag(e);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        handleDrag(e);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleDrag]);

  const handleTimeUpdate = () => {
    const { currentTime } = audioRef.current;
    const maxDuration = audioRef.current.duration;
    const calc = totalLength - (currentTime / maxDuration) * totalLength;
    pathRef.current.style.strokeDashoffset = calc;

    const percentage = (currentTime / maxDuration) * 100;
    const point = pathRef.current.getPointAtLength(
      (percentage / 100) * totalLength
    );
    dotRef.current.setAttribute("cx", point.x);
    dotRef.current.setAttribute("cy", point.y);
  };

  const updateAudio = (percentage) => {
    const audio = audioRef.current;
    const maxDuration = audio.duration;
    const currentTime = (percentage * maxDuration) / 100;
    audio.currentTime = currentTime;
  };

  const playPauseHandler = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      document.querySelectorAll("audio").forEach((a) => a.pause());
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    const circle = circleRef.current;
    const dot = dotRef.current;
    if (!audio) return;
    const path = circle.querySelector("#seekbar");

    path.style.strokeDasharray = totalLength;
    path.style.strokeDashoffset = totalLength;

    const handleAudioEnd = () => {
      setIsPlaying(false);
      path.style.strokeDashoffset = totalLength;
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("ended", handleAudioEnd);

    // Cleanup event listeners
    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("ended", handleAudioEnd);
    };
  }, [isPlaying, totalLength, handleTimeUpdate]);

  return (
    <section className={`slide__audio ${isPlaying ? "playing" : "paused"}`}>
      <audio ref={audioRef} className="slide__audio-player" controls>
        <track kind="captions" />
        <source
          src="https://res.cloudinary.com/dmlwye965/video/upload/v1729079301/oy71ofyrluwjofnoi1fw.wav"
          type="audio/wav"
        />
      </audio>
      <figure className="audio__controls">
        <svg
          version="1.1"
          id="circle"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 100 100"
          ref={circleRef}
          alt="Audio Seekbar"
        >
          <path
            id="background"
            fill="none"
            stroke="#6d6d6d" 
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M50,2.9L50,2.9C76,2.9,97.1,24,97.1,50v0C97.1,76,76,97.1,50,97.1h0C24,97.1,2.9,76,2.9,50v0C2.9,24,24,2.9,50,2.9z"
            onClick={handleDrag}
          />
          <path
            id="seekbar"
            ref={pathRef}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M50,2.9L50,2.9C76,2.9,97.1,24,97.1,50v0C97.1,76,76,97.1,50,97.1h0C24,97.1,2.9,76,2.9,50v0C2.9,24,24,2.9,50,2.9z"
            onMouseDown={handleDrag}
            onMouseUp={handleMouseUp}
          />
          <circle
            className="progress-handle"
            ref={dotRef}
            r="7"
            cx="50"
            cy="2"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="equalizer"
          viewBox="0 0 100 100"
          alt="Equalizer"
        >
          <g className="equalizer-group">
            <rect className="bar" />
            <rect className="bar" />
            <rect className="bar" />
            <rect className="bar" />
            <rect className="bar" />
          </g>
        </svg>

        <button
          className="play-pause"
          onClick={playPauseHandler}
          aria-label="Play Pause Button"
        >
          <span className="hidden">{isPlaying ? "Pause" : "Play"}</span>
        </button>
      </figure>
    </section>
  );
};

export default AudioPlayer;
