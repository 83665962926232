import React, {useEffect, useRef} from "react";

import AcrossIcon from "../../images/icons/across.svg";
import {TimelineLite} from "gsap";
import PropTypes from "prop-types";
import "./modal.css";

const Modal = ({onDeactivateModal}) => {
  const modalDocumentRef = useRef(null);
  let tl = new TimelineLite({paused: true});

  useEffect(() => {
    tl.to(modalDocumentRef.current, 0.35, {opacity: 1}).play();
  });
  return (
    <div
      ref={modalDocumentRef}
      className={`modal-exp__wrapper fade`}
      role="document">
      <button
        className="modal-exp__close"
        id="close-modal"
        onClick={onDeactivateModal}>
        <AcrossIcon aria-hidden={true} />
      </button>
      <div className="modal-exp__body">
        <h2 className="modal-exp__title font-medium" id="exp-title">
          Experience 🎓
        </h2>
        <ul className="modal-exp__list">
          
          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265285417-ac3a12bc-c865-4739-a4bc-27c6caa08d61.png"
                width="15.5px"
                height="15.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://www.syracuse.edu">Syracuse University</a> 
                <br />
                <p className="modal-exp__position">
                  DL Research Intern&nbsp;/ Aug&rsquo;23 — Present  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265284608-b386d79b-e34c-4c3e-9a27-6d3f18094434.png"
                width="15.5px"
                height="15.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://simppl.org">SimPPL</a> 
                <br />
                <p className="modal-exp__position">
                  Applied AI Researcher&nbsp;/ Jun&rsquo;23 — Present  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265284112-e5bb337e-c7be-4ed3-b671-fa55c6bd8d0d.png"
                width="15.5px"
                height="15.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://summerofcode.withgoogle.com/programs/2023/organizations/tensorflow-d1">
                  Google Summer of Code
                </a>
                ,{' '}
                <a href="https://www.tensorflow.org">TensorFlow</a> 
                <br />
                <p className="modal-exp__position">
                  Student Developer&nbsp;/ May&rsquo;23 — Aug&rsquo;23  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265285727-51a11e49-6bec-472e-a614-7de914b0809b.png"
                width="15.5px"
                height="15.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://www.tum.de/en">TU Münich</a> 
                <br />
                <p className="modal-exp__position">
                  CV Research Intern&nbsp;/ June&rsquo;22 — Aug&rsquo;22  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265285901-738b4681-5ef9-42c1-ab63-2d302ac6edce.png"
                width="14.5px"
                height="14.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://fellowship.hackillinois.org">HACKILLINOIS OSF`21</a>,{' '}
                <a href="https://illinois.edu">UIUC</a> 
                <br />
                <p className="modal-exp__position">
                  OpenSource Fellow&nbsp;/ Sept&rsquo;21 — Oct&rsquo;21  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265286122-29f3d298-c293-4571-a26d-51fd9045b8a0.png"
                width="14px"
                height="14px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://www.mitacs.ca/en/programs/globalink/globalink-research-internship">MITACS`21</a>,{' '}
                <a href="https://www.ualberta.ca">UofAlberta</a> 
                <br />
                <p className="modal-exp__position">
                  GRI Research Intern&nbsp;/ Jun&rsquo;21 — Aug&rsquo;21  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265286336-abd74c9d-d4be-4da3-95da-19ab8af59eef.png"
                width="14.5px"
                height="14.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://www.iiit.ac.in">IIIT Hyderabad</a>
                <br />
                <p className="modal-exp__position">
                  Summer Research Intern&nbsp;/ Mar&rsquo;21 — May&rsquo;21  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265286831-21aa20f1-4e7b-4c76-8a86-82c6f5912df5.png"
                width="14.5px"
                height="14.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://layer5.io">Layer5, Inc</a> 
                <br />
                <p className="modal-exp__position">
                  SMP Maintainer under <a className="cncf-link" href="https://www.cncf.io">CNCF</a>&nbsp;/ Jul&rsquo;20 — Jan&rsquo;21  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265286953-18d1feae-994a-453c-ba4a-1a2644e336ce.png"
                width="14.5px"
                height="14.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://www.iitk.ac.in">IIT Kanpur</a> 
                <br />
                <p className="modal-exp__position">
                  Summer Intern&nbsp;/ Apr&rsquo;20 — Jul&rsquo;20  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265287091-affae1b0-d30e-4fed-a300-59efd87b4696.png"
                width="14.5px"
                height="14.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
                <a href="https://spotle.ai">Spotle.AI, Inc</a> 
                <br />
                <p className="modal-exp__position">
                  FrontEnd Intern&nbsp;/ Apr&rsquo;20 — Jun&rsquo;20  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/265287305-d9796d62-45db-4e38-98da-dd678fbaebe5.png"
                width="14.5px"
                height="14.5px"
                className="modal-exp__image mr-2 pb-5 pointer-events-none"
              />
              <p className="modal-exp__company">
              <a href="https://www.tensorflow.org">TensorFlow</a>,{' '}
              <a href="https://codein.withgoogle.com">Google Code-In </a>
                <br />
                <p className="modal-exp__position">
                  Mentor&nbsp;/ Dec&rsquo;19 — Jan&rsquo;20  |  (remote)
                </p>
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '42px' }}></div>

          <div className="modal-exp__item flex items-center">
            <div className="flex items-center">
              <img
                src="https://user-images.githubusercontent.com/48355572/234945747-bf397684-9a28-4601-b81a-c09deff53a49.gif"
                width="18px" height="14px"
                className="modal-exp__image mr-2 pointer-events-none"
              />
              <p className="modal-exp__company font-mono">
              To delve deep, feel free to explore my <a href="https://github.com/Neilblaze/Neilblaze">GitHub</a> profile.
              </p>
            </div>
          </div>

          <div style={{ marginBottom: '12px' }}></div>

        </ul>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onDeactivateModal: PropTypes.func.isRequired,
};

export default Modal;
